body {
  margin: 0;
}

#main-ui {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

#main-canvas {
  display: block;
  width: 100vw;
  height: 100vh;
}